import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
} from "react-admin";
import LinkIcon from "@mui/icons-material/Link";

const DocumentsList = () => {
  const DownloadDocument = ({ url }: { url: string }) => {
    return (
      <a href={url} target="_blank">
        <LinkIcon />
      </a>
    );
  };

  return (
    <List>
      <Datagrid>
        <TextField label="Title" source="title" sortable={false} />
        <TextField label="Description" source="description" sortable={false} />
        <DateField source="timestamp" showTime locales="en-UK" />
        <FunctionField
          label=""
          render={(record: { url: string }) => (
            <DownloadDocument url={record.url} />
          )}
        />
      </Datagrid>
    </List>
  );
};

const Documents = { list: DocumentsList };

export default Documents;
