import React from "react";
import {Button, IconButtonWithTooltip} from "react-admin";

export const DialogButton = (props: { label: string; icon: any; type: "list" | "show"; onClick: any }) => {
    const { label, icon, type, onClick } = props;
    if (type === "show") {
        return (<Button label={label} onClick={onClick}>{icon}</Button>);
    }
    return (
        <IconButtonWithTooltip label={label} onClick={onClick}>
            {icon}
        </IconButtonWithTooltip>
    );
}