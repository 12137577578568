import React from "react";
import { useRecordContext, BooleanField } from "react-admin";
import get from "lodash/get";

const CustomBooleanField = (props: any) => {
  const { source } = props;
  const record = useRecordContext(props);

  let value = get(record, source, null); // Default to null if the record or source is undefined
  value = value === "Y" ? true : false;

  return <BooleanField record={{ [source]: value }} source={source} />;
};

export default CustomBooleanField;
