// in src/admin/index.tsx
import {
  Admin,
  Resource,
  fetchUtils,
  localStorageStore,
  NotFound,
} from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import authProvider from "../authProvider";
import GoogleLoginPage from "../GoogleLoginPage";

import UpdraftDataProvider from "../UpdraftDataProvider";
import Loans from "../loan/Loans";
import { UpdraftLayout } from "./Layout";

const STORE_VERSION = "739";

const httpClient = (url: string, options: any) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};
let dataProvider: any = null;
switch (process.env.REACT_APP_BRANCH) {
  case "staging":
    dataProvider = new UpdraftDataProvider(
      {
        vault: "https://vault-master.api.updraft.co.uk",
      },
      httpClient
    );
    break;
  case "prod":
    dataProvider = new UpdraftDataProvider(
      {
        vault: "https://vault.api.updraft.co.uk",
      },
      httpClient
    );
    break;
  case "warmly":
    dataProvider = new UpdraftDataProvider(
      {
        vault: "https://vault-warmly.api.updraft.co.uk",
      },
      httpClient
    );
    break;
  default:
    dataProvider = new UpdraftDataProvider(
      {
        // vault: "http://localhost:8000",
        vault: "https://vault-master.api.updraft.co.uk"
      },
      httpClient
    );
}

const messages: any = {
  en: englishMessages,
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en", {
  onMissingKey: (key: string) => key,
});

const App = () => (
  <Admin
    catchAll={NotFound}
    store={localStorageStore(STORE_VERSION)}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    loginPage={GoogleLoginPage}
    authProvider={authProvider}
    layout={UpdraftLayout}
  >
    <Resource name="vault/loan/loans" options={{ label: "Loans" }} {...Loans} />
  </Admin>
);

export default App;
