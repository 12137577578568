const authProvider = {
  login: (data: any) => {
    localStorage.setItem("token", data.tokenId);
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkAuth: () => {
    if (!localStorage.getItem("token")) {
      return Promise.reject(new Error("no token stored locally"));
    }
    return Promise.resolve();
  },
  checkError: (error: any) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject(new Error(`Received status: ${status}`));
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    return Promise.resolve()
  },
};

export default authProvider;
