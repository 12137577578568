import {
  DateField,
  Loading,
  NumberField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  useGetOne,
} from "react-admin";
import { MoneyField } from "../admin/MoneyField";
import { Divider, Grid } from "@material-ui/core";
import CustomBooleanField from "../admin/CustomBooleanField";
import { PercentField } from "../admin/PercentField";
import {
  RepaymentMethodChoices,
  PaymentMethodChoices,
  paymentFrequencyChoices,
  purposeChoices,
  simpleInterestRateBasisChoices,
  originationChannelChoices,
  interestRateTypeChoices,
  insuranceTypeChoices,
  insurancePaymentFrequencyChoices,
  PrimaryIncomeVerificationChoices,
  EmploymentStatusChoices,
  ResidentChoices,
  BankruptcyIvaChoices,
  BureauScoreProviderChoices,
  BureauScoreTypeChoices,
  customerTypeChoices,
  GeographicRegionTypeChoices,
  PoolIdentifierTypeChoices,
} from "../admin/BoeReportInterfaces";
import CustomDateField from "../admin/CustomDateField";
import CustomSelectField from "../admin/CustomSelectionFields";

const DailyBoeReport = ({ id }: any) => {
  const { data, isLoading, error } = useGetOne<any>(`vault/boereport/daily/<id>`, { id: id });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const EMPTY_TEXT = "-";

  return (
    <Show>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={3}>
          <SimpleShowLayout record={data} divider={<Divider flexItem />}>
            <SelectField
              label="Payment Frequency"
              source="payment_frequency"
              choices={paymentFrequencyChoices}
              emptyText={EMPTY_TEXT}
            />
            <SelectField label="Purpose" source="purpose" choices={purposeChoices} emptyText={EMPTY_TEXT} />
            <SelectField
              label="Interest Rate Basis"
              source="simple_interest_rate_basis"
              choices={simpleInterestRateBasisChoices}
              emptyText={EMPTY_TEXT}
            />
            <SelectField
              label="Origination Channel"
              source="origination_channel"
              choices={originationChannelChoices}
              emptyText={EMPTY_TEXT}
            />
            <NumberField label="Count of Borrowers" source="count_of_borrowers" emptyText={EMPTY_TEXT} />
            <PercentField
              label="Percent Prepayment Allowed"
              source="percent_prepayment_allowed"
              emptyText={EMPTY_TEXT}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout record={data} divider={<Divider flexItem />}>
            <MoneyField label="Early Repayment Fee" source="early_repayment_fee" emptyText={EMPTY_TEXT} />
            <MoneyField label="Set Off Amount" source="set_off_amount" emptyText={EMPTY_TEXT} />
            <SelectField
              label="Interest Rate Type"
              source="interest_rate_type"
              choices={interestRateTypeChoices}
              emptyText={EMPTY_TEXT}
            />
            <CustomBooleanField label="Regulated Loan" source="regulated_loan" emptyText={EMPTY_TEXT} />
            <CustomBooleanField label="Associated Insurance" source="associated_insurance" emptyText={EMPTY_TEXT} />
            <SelectField
              label="Insurance Type"
              source="insurance_type"
              choices={insuranceTypeChoices}
              emptyText={EMPTY_TEXT}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout record={data} divider={<Divider flexItem />}>
            <MoneyField label="Insurance Amount" source="insurance_amount" emptyText={EMPTY_TEXT} />
            <MoneyField label="Insurance Premium" source="insurance_premium" emptyText={EMPTY_TEXT} />
            <SelectField
              label="Insurance Payment Frequency"
              source="insurance_payment_frequency"
              choices={insurancePaymentFrequencyChoices}
              emptyText={EMPTY_TEXT}
            />
            <TextField label="Insurance Provider" source="insurance_provider" emptyText={EMPTY_TEXT} />
            <DateField label="Pool Cut Off Date" source="pool_cut_off_date" locales="en-UK" emptyText={EMPTY_TEXT} />
            <CustomSelectField
              label="Pool Identifier"
              source="pool_identifier"
              choices={PoolIdentifierTypeChoices}
              emptyText={EMPTY_TEXT}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout record={data} divider={<Divider flexItem />}>
            <DateField label="Pool Addition Date" source="pool_addition_date" locales="en-UK" emptyText={EMPTY_TEXT} />
            <TextField label="Loan Identifier" source="loan_identifier" emptyText={EMPTY_TEXT} />
            <TextField label="Borrower Identifier" source="borrower_identifier" emptyText={EMPTY_TEXT} />
            <TextField label="Credit Quality" source="credit_quality" emptyText={EMPTY_TEXT} />
            <DateField label="Origination Date" source="origination_date" locales="en-UK" emptyText={EMPTY_TEXT} />
            <MoneyField label="Original Principal Balance" source="original_principal_balance" emptyText={EMPTY_TEXT} />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout record={data} divider={<Divider flexItem />}>
            <TextField label="Originator" source="originator" emptyText={EMPTY_TEXT} />
            <TextField label="Service Identifier" source="service_identifier" emptyText={EMPTY_TEXT} />
            <TextField label="Currency" source="currency" emptyText={EMPTY_TEXT} />
            <TextField label="Primary Income Currency" source="primary_income_currency" emptyText={EMPTY_TEXT} />
            <SelectField
              label="Repayment Method"
              source="repayment_method"
              choices={RepaymentMethodChoices}
              emptyText={EMPTY_TEXT}
            />
            <DateField label="Loan Maturity Date" source="loan_maturity_date" locales="en-UK" emptyText={EMPTY_TEXT} />
            <NumberField label="Original Loan Term" source="original_loan_term" emptyText={EMPTY_TEXT} />
            <NumberField label="Remaining Loan Term" source="remaining_loan_term" emptyText={EMPTY_TEXT} />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout record={data} divider={<Divider flexItem />}>
            <MoneyField label="Current Principal Balance" source="current_principal_balance" emptyText={EMPTY_TEXT} />
            <MoneyField
              label="Current Principal Balance with Accrued Interest and Fees"
              source="current_principal_balance_accrued_interest_fees"
              emptyText={EMPTY_TEXT}
            />
            <MoneyField
              label="Scheduled Payment Due Amount"
              source="scheduled_payment_due_amount"
              emptyText={EMPTY_TEXT}
            />
            <PercentField label="Simple Interest Rate" source="simple_interest_rate" emptyText={EMPTY_TEXT} />
            <TextField label="Account Status" source="account_status" emptyText={EMPTY_TEXT} />
            <MoneyField label="Arrears Balance" source="arrears_balance" emptyText={EMPTY_TEXT} />
            <MoneyField
              label="Arrears Balance Capitalised"
              source="arrears_balance_capitalised"
              emptyText={EMPTY_TEXT}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout record={data} divider={<Divider flexItem />}>
            <NumberField label="Days in Arrears" source="days_in_arrears" emptyText={EMPTY_TEXT} />
            <PercentField label="Gross Default Percent" source="gross_default_percent" emptyText={EMPTY_TEXT} />
            <MoneyField label="Gross Default Amount" source="gross_default_amount" emptyText={EMPTY_TEXT} />
            <PercentField label="Net Loss Percent" source="net_loss_percent" emptyText={EMPTY_TEXT} />
            <MoneyField label="Net Loss Amount" source="net_loss_amount" emptyText={EMPTY_TEXT} />
            <MoneyField label="Recoveries Amount" source="recoveries_amount" emptyText={EMPTY_TEXT} />
            <SelectField
              label="Primary Income Verification"
              source="primary_income_verification"
              choices={PrimaryIncomeVerificationChoices}
              emptyText={EMPTY_TEXT}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout record={data} divider={<Divider flexItem />}>
            <SelectField
              label="Employment Status"
              source="employment_status"
              choices={EmploymentStatusChoices}
              emptyText={EMPTY_TEXT}
            />
            <NumberField label="Primary Income" source="primary_income" emptyText={EMPTY_TEXT} />
            <SelectField label="Resident" source="resident" choices={ResidentChoices} emptyText={EMPTY_TEXT} />
            <SelectField
              label="Bankruptcy/IVA"
              source="bankruptcy_iva"
              choices={BankruptcyIvaChoices}
              emptyText={EMPTY_TEXT}
            />
            <SelectField
              label="Bureau Score Provider"
              source="bureau_score_provider"
              choices={BureauScoreProviderChoices}
              emptyText={EMPTY_TEXT}
            />
            <SelectField
              label="Bureau Score Type"
              source="bureau_score_type"
              choices={BureauScoreTypeChoices}
              emptyText={EMPTY_TEXT}
            />
            <DateField label="Bureau Score Date" source="bureau_score_date" locales="en-UK" emptyText={EMPTY_TEXT} />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout record={data} divider={<Divider flexItem />}>
            <TextField label="Bureau Score Value" source="bureau_score_value" emptyText={EMPTY_TEXT} />
            <NumberField label="CCJ Satisfied Count" source="ccj_satisfied_count" emptyText={EMPTY_TEXT} />
            <NumberField label="CCJ Satisfied Amount" source="ccj_satisfied_amount" emptyText={EMPTY_TEXT} />
            <NumberField label="CCJ Unsatisfied Count" source="ccj_unsatisfied_count" emptyText={EMPTY_TEXT} />
            <NumberField label="CCJ Unsatisfied Amount" source="ccj_unsatisfied_amount" emptyText={EMPTY_TEXT} />
            <DateField label="CCJ Last Date" source="ccj_last_date" locales="en-UK" emptyText={EMPTY_TEXT} />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={3}>
          <SimpleShowLayout record={data} divider={<Divider flexItem />}>
            <CustomDateField label="Default Date" source="default_date" locales="en-UK" emptyText={EMPTY_TEXT} />
            <TextField
              label="Customer Type"
              source="customer_type"
              choices={customerTypeChoices}
              emptyText={EMPTY_TEXT}
            />
            <PercentField label="APR" source="apr" emptyText={EMPTY_TEXT} />
            <SelectField
              label="Payment Method"
              source="payment_method"
              choices={PaymentMethodChoices}
              emptyText={EMPTY_TEXT}
            />
            <SelectField
              label="Geographic Region"
              source="geographic_region"
              choices={GeographicRegionTypeChoices}
              emptyText={EMPTY_TEXT}
            />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </Show>
  );
};

export default DailyBoeReport;
