import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";

export const DayInput = (props: any) => {
    const { onChange, name, label, error } = props;
    return (
        <FormControl variant="standard">
            <TextField
                name={name}
                label={label}
                InputProps={{
                    inputProps: {
                        type: 'number',
                        min: 1, max: 31,
                    },
                }}
                onChange={onChange}
                error={error}
            />
        </FormControl>
    );
}