import {
    Datagrid,
    DateField,
    List,
    TextField,
} from "react-admin";
import {MoneyField} from "../admin/MoneyField";

const EMPTY_TEXT = "-"

const PlacementValueEntries = () => {
    return (
        <List pagination={false}>
            <Datagrid bulkActionButtons={false}>
                <DateField label="Placement date" source="at_date" locales="en-UK" sortable={false} />
                <TextField source="type" />
                <MoneyField label="Principal" source="principal" sortable={false} />
                <MoneyField label="Interest" source="interest" sortable={false} />
                <DateField label="End date" source="end_date" locales="en-UK" sortable={false} emptyText={EMPTY_TEXT} />
            </Datagrid>
        </List>
    );
};

const PlacementValues = { list: PlacementValueEntries };

export default PlacementValues;