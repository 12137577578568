import {DateInput} from "./DateInput";
import React from "react";

export const EffectiveDateInput = (props: {onChange: any, error: boolean}) => {
    const  {onChange, error} = props
    const handleChange = (pickerData: any) => {
        if (Object.keys(pickerData).length === 0) {
            onChange(null, true)
        } else {
            onChange(pickerData.format('YYYY-MM-DD'), false)
        }
    }
    return (
        <DateInput label="Effective Date" name="effective_date"
                   onChange={handleChange}
                   error={error}
                   defaultValue={null}
        />
    )
}