import {
    Datagrid,
    DateField,
    List,
} from "react-admin";
import {MoneyField} from "../admin/MoneyField";
const ArrearsEntries = () => {
    return (
        <List>
            <Datagrid bulkActionButtons={false}>
                <DateField label="Date" source="date" locales="en-UK" sortable={false} />
                <MoneyField label="Expected amount" source="expected_amount" sortable={false} />
                <MoneyField label="Total expected to date" source="total_expected_to_date" sortable={false} />
                <MoneyField label="Actual paid in period" source="actual_paid_in_period" sortable={false} />
                <MoneyField label="Total paid to date" source="total_paid_to_date" sortable={false} />
                <MoneyField label="Overpayments" source="overpayments" sortable={false} />
                <MoneyField label="Arrears" source="arrears" sortable={false} />
            </Datagrid>
        </List>
    );
};

const Arrears = { list: ArrearsEntries };

export default Arrears;