import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {Checkbox, FormControlLabel} from "@material-ui/core";

export const CheckboxInput = (props: any) => {
    const { onChange, label, name } = props;
    return (
        <FormControl variant="standard">
            <FormControlLabel
                value={name}
                control={<Checkbox onChange={onChange} inputProps={{ 'aria-label': 'controlled' }} color="default" />}
                label={label}
                labelPlacement="start"

            />
        </FormControl>
    );
}