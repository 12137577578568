import React from "react";
import { useRecordContext } from "ra-core";
import get from "lodash/get";
import { DateField } from "react-admin";

const EMPTY_TEXT = "-";

const CustomDateField = (props: any) => {
  const record = useRecordContext(props);
  const { label, source, locales } = props;

  const value = get(record, source);

  if (value === "ND") {
    return <span>{EMPTY_TEXT}</span>;
  }

  return <DateField {...props} label={label} source={source} locales={locales} emptyText={EMPTY_TEXT} />;
};

export default CustomDateField;
