import React from "react";
import {NumberInput} from "./NumberInput";

export const PaymentNumberInput = (props: {onChange: any, error: boolean}) => {
    const  {onChange, error} = props
    const handleChange = (event: any) => {
        const payment_number = event.target.value
        onChange(payment_number, payment_number <= 0)
    }
    return (
        <NumberInput placeholder="Number Of Payments" name="payment_number"
                   onChange={handleChange}
                   error={error}
        />
    )
}