import {DayInput} from "./DayInput";
import React from "react";

export const RegularPaymentDayInput = (props: {onChange: any, error: boolean}) => {
    const  {onChange, error} = props
    const handleChange = (event: any) => {
        const day = event.target.value
        onChange(day, !day || (day > 31 || day < 1))
    }
    return (
        <DayInput name="regular_payment_day"
                  label="Regular Payment Day"
                  onChange={handleChange}
                  error={error}
        />
    )
}