import {
    BooleanField,
    DateField,
    EmailField,
    NumberField,
    SelectField,
    Show,
    SimpleShowLayout,
    TextField
} from "react-admin";
import {Grid} from "@material-ui/core";
import {MoneyField} from "../admin/MoneyField";
import {PercentField} from "../admin/PercentField";
import React from "react";
import { Divider } from '@mui/material';

const EMPTY_TEXT = "-"

const LoanDetail = () => (
    <Show>
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <SimpleShowLayout divider={<Divider flexItem />} >
                    <TextField label="ID" source="external_id" />
                    <SelectField source="abacus_collections_status" choices={[
                        {id: "UNKNOWN", name: "Unknown"},
                        {id: "GOOD", name: "Good"},
                        {id: "CLOSED", name: "Closed"},
                        {id: "XDAYS", name: "X-Days"},
                        {id: "1_CYCLE", name: "1 Cycle Late"},
                        {id: "2_CYCLE", name: "2 Cycles Late"},
                        {id: "3_CYCLE", name: "3 Cycles Late"},
                        {id: "PAYMENT_ARRANGEMENT", name: "Payment arrangement good"},
                        {id: "PA_1_LATE", name: "Payment arrangement missed 1 payment"},
                        {id: "PA_2_LATE", name: "Payment arrangement missed 2 payments"},
                        {id: "PA_3_LATE", name: "payment arrangement missed 3 payments"},
                    ]} emptyText={EMPTY_TEXT} />
                    <SelectField source="collections_status" choices={[
                        { id: "GOOD", name: "Good" },
                        { id: "CLOSED", name: "Closed" },
                        { id: "EMERGENCY_PAYMENT_FREEZE", name: "Emergency payment freeze" },
                        { id: "PAYMENT_ARRANGEMENT", name: "Payment arrangement" },
                        { id: "PA_1_LATE", name: "PA 1 Cycle Late" },
                        { id: "PA_2_LATE", name: "PA 2 Cycles Late" },
                        { id: "PA_3_LATE", name: "PA 3 or more cycles Late" },
                        { id: "PAYMENT_HOLIDAY", name: "Payment holiday" },
                        { id: "WRITTEN_OFF", name: "Written off" },
                        { id: "XDAYS", name: "X-Days" },
                        { id: "1_CYCLE", name: "1 Cycle Late" },
                        { id: "2_CYCLE", name: "2 Cycles Late" },
                        { id: "3_CYCLE", name: "3 Cycles Late" },
                        { id: "OVER_3_LATE", name: "Over 3 Cycles Late" },
                        { id: "OVER_180_DAYS_LATE", name: "Over 180 days late" },
                        { id: "OVER_270_DAYS_LATE", name: "Over 270 days late" },
                    ]} emptyText={EMPTY_TEXT} />
                    <SelectField source="collections_status_manual" choices={[
                        { id: "GOOD", name: "Good" },
                        { id: "CLOSED", name: "Closed" },
                        { id: "EMERGENCY_PAYMENT_FREEZE", name: "Emergency payment freeze" },
                        { id: "PAYMENT_ARRANGEMENT", name: "Payment arrangement" },
                        { id: "PA_1_LATE", name: "PA 1 Cycle Late" },
                        { id: "PA_2_LATE", name: "PA 2 Cycles Late" },
                        { id: "PA_3_LATE", name: "PA 3 or more cycles Late" },
                        { id: "PAYMENT_HOLIDAY", name: "Payment holiday" },
                        { id: "WRITTEN_OFF", name: "Written off" },
                        { id: "XDAYS", name: "X-Days" },
                        { id: "1_CYCLE", name: "1 Cycle Late" },
                        { id: "2_CYCLE", name: "2 Cycles Late" },
                        { id: "3_CYCLE", name: "3 Cycles Late" },
                        { id: "OVER_3_LATE", name: "Over 3 Cycles Late" },
                        { id: "OVER_180_DAYS_LATE", name: "Over 180 days late" },
                        { id: "OVER_270_DAYS_LATE", name: "Over 270 days late" },
                        { id: "USEDEFAULT", name: "-" },
                    ]} emptyText={EMPTY_TEXT} />
                    <SelectField source="user_status" choices={[
                        { id: "GOOD", name: "Good" },
                        { id: "OVERDUE", name: "Overdue" },
                        { id: "CLOSED", name: "Closed" },
                        { id: "PAIDLATE", name: "Paid Late" },
                        { id: "DEFAULT", name: "Defaulted" },
                        { id: "PENDING", name: "Pending" },
                        { id: "HOLIDAY", name: "Payment Holiday" },
                        { id: "ARRANGEMENT", name: "Payment Arrangement" },
                    ]} emptyText={EMPTY_TEXT} />
                    <SelectField source="user_status_manual" choices={[
                        { id: "GOOD", name: "Good" },
                        { id: "OVERDUE", name: "Overdue" },
                        { id: "CLOSED", name: "Closed" },
                        { id: "PAIDLATE", name: "Paid Late" },
                        { id: "DEFAULT", name: "Defaulted" },
                        { id: "PENDING", name: "Pending" },
                        { id: "HOLIDAY", name: "Payment Holiday" },
                        { id: "ARRANGEMENT", name: "Payment Arrangement" },
                        { id: "USEDEFAULT", name: "-" },
                    ]} emptyText={EMPTY_TEXT} />
                    <TextField source="good_collections_status_since_date"  emptyText={EMPTY_TEXT} />
                    <MoneyField source="fee_amount" emptyText={EMPTY_TEXT} />
                    <MoneyField source="current_payment_amount" emptyText={EMPTY_TEXT} />
                    <NumberField source="current_payment_day" emptyText={EMPTY_TEXT} />
                    <DateField source="predicted_payment.date" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <SelectField source="payment_method" choices={[
                        { id: "DD", name: "DD - repeating" },
                        { id: "DD-INDIVIDUAL", name: "DD - individual" },
                        { id: "BACS", name: "BACS" },
                        { id: "MANUAL", name: "Manual" },
                        { id: "SO", name: "Standing Order" },
                    ]} emptyText={EMPTY_TEXT} />
                    <DateField source="payment_arrangement_requested" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <DateField source="payment_arrangement_started" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <DateField source="payment_arrangement_ended" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <MoneyField source="payment_arrangement_amount" emptyText={EMPTY_TEXT} />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
                <SimpleShowLayout divider={<Divider flexItem />}>
                    <DateField source="dmp_requested" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <DateField source="dmp_started" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <DateField source="dmp_ended" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <DateField source="emergency_freeze_requested" emptyText={EMPTY_TEXT} />
                    <NumberField source="emergency_freeze_duration" emptyText={EMPTY_TEXT} />
                    <MoneyField source="partial_settlement_amount" emptyText={EMPTY_TEXT} />
                    <DateField source="partial_settlement_date" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <SelectField source="reporting_status" choices={[
                        { id: "0", name: "0" },
                        { id: "1", name: "1 Cycle delinquent" },
                        { id: "2", name: "2 Cycle delinquent" },
                        { id: "3", name: "3 Cycle delinquent" },
                        { id: "4", name: "4 Cycle delinquent" },
                        { id: "6", name: "6 Cycle delinquent" },
                        { id: "I", name: "Payment Arrangement" },
                        { id: "D", name: "Default" },
                        { id: "S", name: "Settled" },
                        { id: "U", name: "Unclassified" },
                    ]} emptyText={EMPTY_TEXT} />
                    <SelectField source="reporting_status_manual" choices={[
                        { id: "0", name: "0" },
                        { id: "1", name: "1 Cycle delinquent" },
                        { id: "2", name: "2 Cycle delinquent" },
                        { id: "3", name: "3 Cycle delinquent" },
                        { id: "4", name: "4 Cycle delinquent" },
                        { id: "6", name: "6 Cycle delinquent" },
                        { id: "I", name: "Payment Arrangement" },
                        { id: "D", name: "Default" },
                        { id: "S", name: "Settled" },
                        { id: "U", name: "Unclassified" },
                        { id: "USEDEFAULT", name: "-" },
                    ]} emptyText={EMPTY_TEXT} />
                    <SelectField source="old_reporting_status" choices={[
                        { id: "0", name: "0" },
                        { id: "1", name: "1 Cycle delinquent" },
                        { id: "2", name: "2 Cycle delinquent" },
                        { id: "3", name: "3 Cycle delinquent" },
                        { id: "4", name: "4 Cycle delinquent" },
                        { id: "6", name: "6 Cycle delinquent" },
                        { id: "I", name: "Payment Arrangement" },
                        { id: "D", name: "Default" },
                        { id: "S", name: "Settled" },
                        { id: "U", name: "Unclassified" },
                    ]} emptyText={EMPTY_TEXT} />
                    <SelectField source="insight_flags" choices={[
                        { id: "D", name: "Deceased" },
                        { id: "G", name: "Gone Away" },
                        { id: "M", name: "Debt Management Plan" },
                        { id: "P", name: "Partial Settlment" },
                        { id: "I", name: "Paid by third party" },
                        { id: "V", name: "Voluntary termination" },
                        { id: "C", name: "Assigned non Insight member" },
                        { id: "S", name: "Assigned Insight member" },
                    ]} emptyText="No Flag" />
                    <SelectField source="individual_cycle_status" choices={[
                        { id: "GOOD", name: "Good" },
                        { id: "CLOSED", name: "Closed" },
                        { id: "PAYMENT_ARRANGEMENT", name: "Payment arrangement" },
                        { id: "WRITTEN_OFF", name: "Written off" },
                        { id: "1_CYCLE", name: "1 Cycle Late" },
                        { id: "2_CYCLE", name: "2 Cycles Late" },
                        { id: "3_CYCLE", name: "3 Cycles Late" },
                        { id: "OVER_3_LATE", name: "Over 3 Cycles Late" },
                    ]} emptyText={EMPTY_TEXT} />
                    <SelectField source="payment_status" choices={[
                        { id: "ONTIME", name: "On time" },
                        { id: "LATE", name: "Late" },
                        { id: "UNPAID", name: "Unpaid" },
                        { id: "SETTLED", name: "Settled" },
                    ]} emptyText={EMPTY_TEXT} />
                    <NumberField source="payment_days_late" emptyText={EMPTY_TEXT} />
                    <TextField label="Mifos ID" source="mifos_id" emptyText={EMPTY_TEXT} />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
                <SimpleShowLayout divider={<Divider flexItem />}>
                    <EmailField source="email" emptyText={EMPTY_TEXT} />
                    <DateField source="first_payment_date" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <NumberField source="regular_payment_day" emptyText={EMPTY_TEXT} />
                    <MoneyField source="regular_payment_amount" emptyText={EMPTY_TEXT} />
                    <PercentField source="apr" emptyText={EMPTY_TEXT} />
                    <PercentField source="interest_at_origination" emptyText={EMPTY_TEXT} />
                    <NumberField source="duration" emptyText={EMPTY_TEXT} />
                    <NumberField source="duration_at_origination" emptyText={EMPTY_TEXT} />
                    <TextField source="product" emptyText={EMPTY_TEXT} />
                    <TextField source="product_at_origination" emptyText={EMPTY_TEXT} />
                    <MoneyField source="disbursed_amount" emptyText={EMPTY_TEXT} />
                    <DateField source="disbursed_date" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <DateField source="default_date" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <MoneyField source="default_amount" emptyText={EMPTY_TEXT} />
                    <DateField source="original_final_payment_date" locales="en-UK" emptyText={EMPTY_TEXT} />
                </SimpleShowLayout>
            </Grid>
            <Grid item xs={3}>
                <SimpleShowLayout divider={<Divider flexItem />}>
                    <MoneyField source="original_final_payment_amount" emptyText={EMPTY_TEXT} />
                    <MoneyField source="original_interest_amount" emptyText={EMPTY_TEXT} />
                    <DateField source="final_payment_date" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <MoneyField source="final_payment_amount" emptyText={EMPTY_TEXT} />
                    <DateField source="settled_date" locales="en-UK" emptyText={EMPTY_TEXT} />
                    <TextField source="country" emptyText={EMPTY_TEXT} />
                    <TextField source="fund" emptyText={EMPTY_TEXT} />
                    <BooleanField source="group_policy" emptyText={EMPTY_TEXT} />
                    <TextField source="pilot" emptyText={EMPTY_TEXT} />
                    <TextField source="product_segment" emptyText={EMPTY_TEXT} />
                    <BooleanField source="in_share_report" emptyText={EMPTY_TEXT} />
                    <TextField source="debt_collection_agency" emptyText={EMPTY_TEXT} />
                    <NumberField source="payment_days_overdue" emptyText={EMPTY_TEXT} />
                    <MoneyField source="current_balance" emptyText={EMPTY_TEXT} />
                    <MoneyField source="current_arrears" emptyText={EMPTY_TEXT} />
                </SimpleShowLayout>
            </Grid>
        </Grid>
    </Show>
);

export default LoanDetail;