import {
    BooleanField,
    DatagridConfigurable,
    DateField,
    EmailField, ExportButton, FilterButton,
    List, ListProps,
    NumberField, SelectColumnsButton, SelectField,
    TextField,
    TextInput, TopToolbar, FunctionField,
} from "react-admin";
import {MoneyField} from "../admin/MoneyField";
import React, {Fragment} from "react";
import {ShowButton} from 'react-admin';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ChangeCircleSharpIcon from '@mui/icons-material/ChangeCircleSharp';
import PauseCircleSharpIcon from '@mui/icons-material/PauseCircleSharp';
import AdjustSharpIcon from '@mui/icons-material/AdjustSharp';
import AddDDChange from "../loanaccount/AddDDChange";
import AddPaymentHoliday from "../loanaccount/AddPaymentHoliday";
import PostTransactionDialog from "../loanaccount/PostTransaction";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import AddReducedPaymentOrDMP from "../loanaccount/AddReducedPaymentOrDMPDialog";
import RepayLoanDialog from "../loanaccount/PrepayLoanDialog";
import PlacementValueDialog from "../loanaccount/PlacementValueDialog";
import {useRecordContext} from "ra-core";

const LoanList = (props: ListProps) => {
    const EMPTY_TEXT = `-`

    const loanFilters = [
        <TextInput label="Loan ID" source="id" alwaysOn name="id"/>,
    ];

    const LoanListActions = () => (
        <TopToolbar>
            <SelectColumnsButton/>
            <FilterButton/>
            <ExportButton/>
        </TopToolbar>
    );

    const record = useRecordContext(props);
    const loan_closed = record && record.hasOwnProperty('abacus_collections_status') && record.abacus_collections_status === "CLOSED"

    let MutationButtons
    let TransactionButtons
//     if (loan_closed) {
//         MutationButtons = () => (<></>)
//         TransactionButtons = () => (<></>)
//     } else {
        MutationButtons = () => (
            <Fragment>
                <FunctionField render={(record: { id: string; }) => <AddDDChange
                    id={record.id} icon={<ChangeCircleSharpIcon color="info"/>}
                    button_type="list" fields={["regular_payment_day", "effective_date", "contracted"]}/>}
                />
                <FunctionField render={(record: { id: string; }) => <AddPaymentHoliday
                    id={record.id} icon={<PauseCircleSharpIcon color="info"/>}
                    button_type="list" fields={["next_payment_on_or_after", "effective_date", "contracted"]}/>}
                />
                <FunctionField render={(record: { id: string; }) => <AddReducedPaymentOrDMP
                    id={record.id} icon={<AdjustSharpIcon color="info"/>}
                    button_type="list" fields={["amount", "interest", "effective_date"]} purpose="REDUCED_PAYMENT" label="Reduced Payment" />}
                />
                <FunctionField render={(record: { id: string; }) => <AddReducedPaymentOrDMP
                    id={record.id} icon={<RadioButtonCheckedIcon color="info"/>}
                    button_type="list" fields={["amount", "interest", "effective_date"]} purpose="DMP" label="DMP" />}
                />
            </Fragment>
        )
        TransactionButtons = () => (
            <Fragment>
                <FunctionField render={(record: { id: string; }) => <PostTransactionDialog
                    id={record.id} icon={<AddCircleIcon color="info"/>}
                    type="REPAYMENT" button_type="list" fields={["amount", "paid_at"]}/>}
                />
                <FunctionField render={(record: { id: string; }) => <PostTransactionDialog
                    id={record.id} icon={<ArrowCircleDownIcon color="info"/>}
                    type="INTEREST_WAIVER" button_type="list" fields={["amount", "paid_at"]}/>}
                />
                <FunctionField render={(record: { id: string; }) => <PostTransactionDialog
                    id={record.id} icon={<AddCircleOutlineIcon color="info"/>}
                    type="REFUND" button_type="list" fields={["amount", "paid_at"]}/>}
                />
                <FunctionField render={(record: { id: string; }) => <PostTransactionDialog
                    id={record.id} icon={<HighlightOffIcon color="info"/>}
                    type="PRINCIPAL_WRITEOFF" button_type="list" fields={["amount", "paid_at"]}/>}
                />
                <FunctionField render={(record: { id: string; }) => <RepayLoanDialog
                    id={record.id} icon={<CheckCircleOutlineIcon color="info"/>}
                    button_type="list" fields={["amount", "principal", "interest", "paid_at"]}/>}
                />
                <FunctionField render={(record: { id: string; }) => <PlacementValueDialog
                    id={record.id} icon={<CheckCircleOutlineIcon color="info"/>}
                    button_type="list" fields={["amount", "principal", "interest", "paid_at"]}/>}
                />
            </Fragment>
        )
//     }
    return (
        <List
            {...props}
            filters={loanFilters}
            actions={<LoanListActions/>}
        >
            <DatagridConfigurable
                bulkActionButtons={false}
                rowClick="" omit={[
                // "getOne",
                // "external_id",
                // "abacus_collections_status",
                "collections_status",
                "collections_status_manual",
                "good_collections_status_since_date",
                "fee_amount",
                "current_payment_amount",
                "current_payment_day",
                "predicted_payment.date",
                "payment_method",
                "payment_arrangement_requested",
                "payment_arrangement_started",
                "payment_arrangement_ended",
                "payment_arrangement_amount",
                "dmp_requested",
                "dmp_started",
                "dmp_ended",
                "emergency_freeze_requested",
                "emergency_freeze_duration",
                "partial_settlement_amount",
                "partial_settlement_date",
                // "reporting_status",
                "reporting_status_manual",
                "old_reporting_status",
                "insight_flags",
                // "user_status",
                "user_status_manual",
                // "individual_cycle_status",
                // "payment_status",
                "payment_days_late",
                "mifos_id",
                // "user_id",
                "email",
                "first_payment_date",
                "regular_payment_day",
                "regular_payment_amount",
                "interest",
                "interest_at_origination",
                "duration",
                "duration_at_origination",
                "product",
                "product_at_origination",
                "disbursed_amount",
                "disbursed_date",
                "default_date",
                "default_amount",
                "original_final_payment_date",
                "original_final_payment_amount",
                "original_interest_amount",
                "final_payment_date",
                "final_payment_amount",
                "settled_date",
                "country",
                "fund",
                "group_policy",
                "pilot",
                "product_segment",
                "in_share_report",
                "debt_collection_agency",
                "payment_days_overdue",
                // "current_balance",
                // "current_arrears",
            ]}>
                {/*<NumberField source="getOne" />*/}
                <TextField label="ID" source="external_id" />
                <SelectField source="abacus_collections_status" choices={[
                    {id: "UNKNOWN", name: "Unknown"},
                    {id: "GOOD", name: "Good"},
                    {id: "CLOSED", name: "Closed"},
                    {id: "XDAYS", name: "X-Days"},
                    {id: "1_CYCLE", name: "1 Cycle Late"},
                    {id: "2_CYCLE", name: "2 Cycles Late"},
                    {id: "3_CYCLE", name: "3 Cycles Late"},
                    {id: "PAYMENT_ARRANGEMENT", name: "Payment arrangement good"},
                    {id: "PA_1_LATE", name: "Payment arrangement missed 1 payment"},
                    {id: "PA_2_LATE", name: "Payment arrangement missed 2 payments"},
                    {id: "PA_3_LATE", name: "payment arrangement missed 3 payments"},
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <SelectField source="collections_status" choices={[
                    {id: "GOOD", name: "Good"},
                    {id: "CLOSED", name: "Closed"},
                    {id: "EMERGENCY_PAYMENT_FREEZE", name: "Emergency payment freeze"},
                    {id: "PAYMENT_ARRANGEMENT", name: "Payment arrangement"},
                    {id: "PA_1_LATE", name: "PA 1 Cycle Late"},
                    {id: "PA_2_LATE", name: "PA 2 Cycles Late"},
                    {id: "PA_3_LATE", name: "PA 3 or more cycles Late"},
                    {id: "PAYMENT_HOLIDAY", name: "Payment holiday"},
                    {id: "WRITTEN_OFF", name: "Written off"},
                    {id: "XDAYS", name: "X-Days"},
                    {id: "1_CYCLE", name: "1 Cycle Late"},
                    {id: "2_CYCLE", name: "2 Cycles Late"},
                    {id: "3_CYCLE", name: "3 Cycles Late"},
                    {id: "OVER_3_LATE", name: "Over 3 Cycles Late"},
                    {id: "OVER_180_DAYS_LATE", name: "Over 180 days late"},
                    {id: "OVER_270_DAYS_LATE", name: "Over 270 days late"},
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <SelectField source="collections_status_manual" choices={[
                    {id: "GOOD", name: "Good"},
                    {id: "CLOSED", name: "Closed"},
                    {id: "EMERGENCY_PAYMENT_FREEZE", name: "Emergency payment freeze"},
                    {id: "PAYMENT_ARRANGEMENT", name: "Payment arrangement"},
                    {id: "PA_1_LATE", name: "PA 1 Cycle Late"},
                    {id: "PA_2_LATE", name: "PA 2 Cycles Late"},
                    {id: "PA_3_LATE", name: "PA 3 or more cycles Late"},
                    {id: "PAYMENT_HOLIDAY", name: "Payment holiday"},
                    {id: "WRITTEN_OFF", name: "Written off"},
                    {id: "XDAYS", name: "X-Days"},
                    {id: "1_CYCLE", name: "1 Cycle Late"},
                    {id: "2_CYCLE", name: "2 Cycles Late"},
                    {id: "3_CYCLE", name: "3 Cycles Late"},
                    {id: "OVER_3_LATE", name: "Over 3 Cycles Late"},
                    {id: "OVER_180_DAYS_LATE", name: "Over 180 days late"},
                    {id: "OVER_270_DAYS_LATE", name: "Over 270 days late"},
                    {id: "USEDEFAULT", name: "-"},
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <SelectField source="user_status" choices={[
                    {id: "GOOD", name: "Good"},
                    {id: "OVERDUE", name: "Overdue"},
                    {id: "CLOSED", name: "Closed"},
                    {id: "PAIDLATE", name: "Paid Late"},
                    {id: "DEFAULT", name: "Defaulted"},
                    {id: "PENDING", name: "Pending"},
                    {id: "HOLIDAY", name: "Payment Holiday"},
                    {id: "ARRANGEMENT", name: "Payment Arrangement"},
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <SelectField source="user_status_manual" choices={[
                    {id: "GOOD", name: "Good"},
                    {id: "OVERDUE", name: "Overdue"},
                    {id: "CLOSED", name: "Closed"},
                    {id: "PAIDLATE", name: "Paid Late"},
                    {id: "DEFAULT", name: "Defaulted"},
                    {id: "PENDING", name: "Pending"},
                    {id: "HOLIDAY", name: "Payment Holiday"},
                    {id: "ARRANGEMENT", name: "Payment Arrangement"},
                    {id: "USEDEFAULT", name: "-"},
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <TextField source="good_collections_status_since_date" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="fee_amount" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="current_payment_amount" emptyText={EMPTY_TEXT} sortable={false} />
                <NumberField source="current_payment_day" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="predicted_payment.date" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <SelectField source="payment_method" choices={[
                    {id: "DD", name: "DD - repeating"},
                    {id: "DD-INDIVIDUAL", name: "DD - individual"},
                    {id: "BACS", name: "BACS"},
                    {id: "MANUAL", name: "Manual"},
                    {id: "SO", name: "Standing Order"},
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="payment_arrangement_requested" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="payment_arrangement_started" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="payment_arrangement_ended" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="payment_arrangement_amount" emptyText={EMPTY_TEXT} sortable={false} />


                <DateField source="dmp_requested" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="dmp_started" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="dmp_ended" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="emergency_freeze_requested" emptyText={EMPTY_TEXT} sortable={false} />
                <NumberField source="emergency_freeze_duration" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="partial_settlement_amount" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="partial_settlement_date" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <SelectField source="reporting_status" choices={[
                    { id: "0", name: "0" },
                    { id: "1", name: "1 Cycle delinquent" },
                    { id: "2", name: "2 Cycle delinquent" },
                    { id: "3", name: "3 Cycle delinquent" },
                    { id: "4", name: "4 Cycle delinquent" },
                    { id: "6", name: "6 Cycle delinquent" },
                    { id: "I", name: "Payment Arrangement" },
                    { id: "D", name: "Default" },
                    { id: "S", name: "Settled" },
                    { id: "U", name: "Unclassified" },
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <SelectField source="reporting_status_manual" choices={[
                    { id: "0", name: "0" },
                    { id: "1", name: "1 Cycle delinquent" },
                    { id: "2", name: "2 Cycle delinquent" },
                    { id: "3", name: "3 Cycle delinquent" },
                    { id: "4", name: "4 Cycle delinquent" },
                    { id: "6", name: "6 Cycle delinquent" },
                    { id: "I", name: "Payment Arrangement" },
                    { id: "D", name: "Default" },
                    { id: "S", name: "Settled" },
                    { id: "U", name: "Unclassified" },
                    { id: "USEDEFAULT", name: "-" },
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <SelectField source="old_reporting_status" choices={[
                    { id: "0", name: "0" },
                    { id: "1", name: "1 Cycle delinquent" },
                    { id: "2", name: "2 Cycle delinquent" },
                    { id: "3", name: "3 Cycle delinquent" },
                    { id: "4", name: "4 Cycle delinquent" },
                    { id: "6", name: "6 Cycle delinquent" },
                    { id: "I", name: "Payment Arrangement" },
                    { id: "D", name: "Default" },
                    { id: "S", name: "Settled" },
                    { id: "U", name: "Unclassified" },
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <SelectField source="insight_flags" choices={[
                    { id: "D", name: "Deceased" },
                    { id: "G", name: "Gone Away" },
                    { id: "M", name: "Debt Management Plan" },
                    { id: "P", name: "Partial Settlment" },
                    { id: "I", name: "Paid by third party" },
                    { id: "V", name: "Voluntary termination" },
                    { id: "C", name: "Assigned non Insight member" },
                    { id: "S", name: "Assigned Insight member" },
                ]} emptyText="No Flag" sortable={false} />
                <SelectField source="individual_cycle_status" choices={[
                    { id: "GOOD", name: "Good" },
                    { id: "CLOSED", name: "Closed" },
                    { id: "PAYMENT_ARRANGEMENT", name: "Payment arrangement" },
                    { id: "WRITTEN_OFF", name: "Written off" },
                    { id: "1_CYCLE", name: "1 Cycle Late" },
                    { id: "2_CYCLE", name: "2 Cycles Late" },
                    { id: "3_CYCLE", name: "3 Cycles Late" },
                    { id: "OVER_3_LATE", name: "Over 3 Cycles Late" },
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <SelectField source="payment_status" choices={[
                    { id: "ONTIME", name: "On time" },
                    { id: "LATE", name: "Late" },
                    { id: "UNPAID", name: "Unpaid" },
                    { id: "SETTLED", name: "Settled" },
                ]} emptyText={EMPTY_TEXT} sortable={false} />
                <NumberField source="payment_days_late" emptyText={EMPTY_TEXT} sortable={false} />
                <TextField label="Mifos ID" source="mifos_id" emptyText={EMPTY_TEXT} sortable={false} />


                {/*<TextField source="user_id" />*/}
                <EmailField source="email" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="first_payment_date" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <NumberField source="regular_payment_day" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="regular_payment_amount" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="interest" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="interest_at_origination" emptyText={EMPTY_TEXT} sortable={false} />
                <NumberField source="duration" emptyText={EMPTY_TEXT} sortable={false} />
                <NumberField source="duration_at_origination" emptyText={EMPTY_TEXT} sortable={false} />
                <TextField source="product" emptyText={EMPTY_TEXT} sortable={false} />
                <TextField source="product_at_origination" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="disbursed_amount" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="disbursed_date" locales="en-UK" emptyText={EMPTY_TEXT} />
                <DateField source="default_date" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="default_amount" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="original_final_payment_date" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />


                <MoneyField source="original_final_payment_amount" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="original_interest_amount" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="final_payment_date" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="final_payment_amount" emptyText={EMPTY_TEXT} sortable={false} />
                <DateField source="settled_date" locales="en-UK" emptyText={EMPTY_TEXT} sortable={false} />
                <TextField source="country" emptyText={EMPTY_TEXT} sortable={false} />
                <TextField source="fund" emptyText={EMPTY_TEXT} sortable={false} />
                <BooleanField source="group_policy" emptyText={EMPTY_TEXT} sortable={false} />
                <TextField source="pilot" emptyText={EMPTY_TEXT} sortable={false} />
                <TextField source="product_segment" emptyText={EMPTY_TEXT} sortable={false} />
                <BooleanField source="in_share_report" emptyText={EMPTY_TEXT} sortable={false} />
                <TextField source="debt_collection_agency" emptyText={EMPTY_TEXT} sortable={false} />
                <NumberField source="payment_days_overdue" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="current_balance" emptyText={EMPTY_TEXT} sortable={false} />
                <MoneyField source="current_arrears" emptyText={EMPTY_TEXT} sortable={false} />

                <Fragment>
                    <ShowButton label=""/>
                </Fragment>
                <MutationButtons />
                <TransactionButtons />
            </DatagridConfigurable>
        </List>
    );
};
export default LoanList;
