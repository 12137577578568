import {
    Datagrid,
    DateField,
    List,
    TextField,
    NumberField,
    BooleanField
} from "react-admin";
import {MoneyField} from "../admin/MoneyField";

const EMPTY_TEXT = "-"

const MutationEntries = () => {
    return (
        <List pagination={false}>
            <Datagrid bulkActionButtons={false}>
                <BooleanField source="contracted" />
                <DateField label="Effective date" source="effective_date" locales="en-UK" sortable={false} />
                <NumberField source="regular_payment_day" emptyText={EMPTY_TEXT} />
                <DateField label="Next payment on or after" source="next_payment_on_or_after" locales="en-UK" sortable={false} emptyText={EMPTY_TEXT} />
                <MoneyField label="Regular payment amount" source="regular_payment_amount" sortable={false} />
                <MoneyField label="Interest rate" source="interest_rate" sortable={false} />
                <TextField label="Actor" source="actor" />
                <TextField label="Purpose" source="purpose" />
            </Datagrid>
        </List>
    );
};

const Mutations = { list: MutationEntries };

export default Mutations;