import React from "react";
import {PercentInput} from "./PercentInput";

export const InterestInput = (props: {onChange: any, error: boolean}) => {
    const  {onChange, error} = props
    const handleChange = (event: any) => {
        const interest = event.target.value
        if (interest.length === 0) {
            onChange(null, true)
        } else {
            onChange(interest, false)
        }
    }
    return (
        <PercentInput placeholder="Interest" name="interest"
                   onChange={handleChange}
                   error={error}
        />
    )
}