import * as React from "react";
import { useRecordContext } from "ra-core";
import { FunctionField, NumberField } from "react-admin";
import get from "lodash/get";

/**
 * This fixes money formatting for our Decimals, that arrive here as a strings.
 * React admin <NumberField source={source} locales="en-UK" options={{ style: 'currency', currency: 'GBP' }} />
 * works for number but not for strings
 */

export const MoneyField = (props: any) => {
  const { label, source } = props;
  const record = useRecordContext(props);
  const options = {
    style: "currency",
    currency: "GBP",
  };

  let value = get(record, source);

  if (value === "ND") {
    return <FunctionField label={label} textAlign="right" render={() => "-"} />;
  }

  if (typeof value === "number") {
    return <NumberField {...props} locales="en-GB" options={options} />;
  }

  if (value === null) {
    return <FunctionField label={label} textAlign="right" render={() => "-"} />;
  }

  return <FunctionField label={label} textAlign="right" render={() => (value * 1).toLocaleString("en-GB", options)} />;
};
