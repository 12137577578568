import { stringify } from "query-string";
// tiny-async-pool tries to be clever and inspect process.version to use es6 or
// es7. This breaks webpack...
import asyncPool from "tiny-async-pool/lib/es7";
import {
  fetchUtils,
  Identifier,
  RaRecord,
  DataProvider,
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
  PaginationPayload,
  SortPayload,
} from "react-admin";

type ServiceMap = { [service: string]: string };

type Data = {
  [key: string]: any;
};

type Params<DataType extends Data> = {
  id?: Identifier;
  ids?: Identifier[];
  action?: string;
  target?: string;
  pagination?: PaginationPayload;
  sort?: SortPayload;
  filter?: { [index: string]: number | string };
  data?: DataType;
  meta?: { [key: string]: any };
};

const ASYNC_POOL_SIZE = 10;

const NATIVE_GET_MANY_RESOURCES = new Set([
  "loans/authentication/users",
  "loans/zoral/decisions",
]);

const transformListResults = (items: any) => {
  let results = []
  for (const result of items) {
    if (result.hasOwnProperty("external_id") && result.hasOwnProperty("id")) {
      result.id = result.external_id
    }
    results.push(result)
  }
  return results
}

export default class UpdraftDataProvider implements DataProvider {
  private serviceMap: ServiceMap;
  private httpClient: typeof fetchUtils.fetchJson;

  constructor(serviceMap: ServiceMap, httpClient: typeof fetchUtils.fetchJson) {
    this.serviceMap = serviceMap;
    this.httpClient = httpClient;
  }

  getServiceUrl(service: string): string | null {
    const url = this.serviceMap[service];

    return url || null; // coalesce undefined to null.
  }

  async request<DataType extends Data>(
    method: string,
    resource: string,
    params: Params<DataType>,
    idempotencyKey: string|null = null
  ) {
    let url = this.createUrl(this.serviceMap, resource, params);
    let body = params.data == null ? null : JSON.stringify(params.data);

    // bit of a hack to turn of pagination during get-many requests
    const pagination_mode = Array.isArray(params.ids)
      ? "none"
      : "partial_pagination";

    if (url.includes("collection/placement_value") && idempotencyKey != null) {
      body = null
      const query = params.data ? 'date=' + params.data['date'] : ''
      url = url + query
      return await this.httpClient(url, {
        method,
        body,
        headers: new Headers({
          Accept: "application/json",
          "X-Pagination-Mode": pagination_mode,
          "X-Idempotency-Key": idempotencyKey
        }),
      });
    }

    if (url.includes("loanaccount/transactions") && idempotencyKey != null) {
      return await this.httpClient(url, {
        method,
        body,
        headers: new Headers({
          Accept: "application/json",
          "X-Pagination-Mode": pagination_mode,
          "X-Idempotency-Key": idempotencyKey
        }),
      });
    }

    return await this.httpClient(url, {
      method,
      body,
      headers: new Headers({
        Accept: "application/json",
        "X-Pagination-Mode": pagination_mode,
      }),
    });
  }

  private createUrl<DataType extends Data>(
    serviceMap: ServiceMap,
    resource: string,
    params: Params<DataType>
  ) {
    const parts = resource.split("/");
    const apiName = parts.shift();

    if (apiName == null || parts.length === 0) {
      throw new Error(`Not enough path segments in resource: ${resource}`);
    }

    const apiUrl = serviceMap[apiName];

    const query: { [key: string]: string | number } = {};
    if (params.pagination != null) {
      const { page, perPage } = params.pagination;
      query.page = page;
      query.page_size = perPage;
    }
    if (params.sort != null) {
      const { field, order } = params.sort;
      query.ordering = `${order === "ASC" ? "" : "-"}${field}`;
    }
    if (params.filter != null) {
      if (params.filter.id != null)
        Object.assign(query, {"external_id": params.filter.id})
      else
        Object.assign(query, params.filter);
    }
    if (params.target != null && params.id != null) {
      query[params.target] = params.id;
    }
    if (params.meta) {
      for (var i in params.meta) {
        query[i] = params.meta[i];
      }
    }

    // add the id into the path only if target is not set.
    if (params.id != null && params.target == null) {
      parts.push(params.id.toString());
    }

    if (Array.isArray(params.ids)) {
      query["id__in"] = params.ids.join(",");
    }

    // speecial case for loans.api's user api which uses cognito_id by default
    if (resource === "loans/authentication/users" && params.id != null) {
      query["lookup_field"] = "id";
    }

    if (resource.includes("vault/loan/mutation/")) {
      return `${apiUrl}/${parts.join("/")}`
    }

    if (params.action != null) {
      parts.push(params.action);
    }

    return `${apiUrl}/${parts.join("/")}/?${stringify(query)}`;
  }

  private unpackListResult<RecordType extends RaRecord>({
    headers,
    json,
  }: {
    headers: Headers;
    json: any;
  }): GetListResult<RecordType> {
    // handle non-paginated endpoints.
    if (Array.isArray(json)) {
      return {
        data: json,
        total: json.length,
      };
    }

    if (json.count != null && Array.isArray(json.results)) {
      return { data: transformListResults(json.results), total: json.count };
    }
    if (json.count === undefined && Array.isArray(json.results)) {
      // cursor pagination
      return {
        data: transformListResults(json.results),
        pageInfo: {
          hasPreviousPage: json.previous != null,
          hasNextPage: json.next != null,
        },
      };
    }

    const contentRange = headers.get("content-range");
    if (contentRange != null) {
      // parse a header that looks something like this:
      // "Content-Range: posts 0-24/319"
      const parts = contentRange.split("/");
      if (parts.length === 2) {
        return {
          data: json,
          total: parseInt(parts[1], 10),
        };
      }
    }

    if ("detail" in json && json.detail === "Invalid page.") {
      return { data: [], total: 0 };
    }

    // if we get here, we couldn't parse the response.
    throw new Error(
      "The total number of results is unknown. The DRF data provider expects " +
        "responses for lists of resources to contain this information to " +
        "build the pagination. If you're not using the " +
        "default PageNumberPagination class, please include this " +
        'information using the Content-Range header OR a "count" key ' +
        "inside the response."
    );
  }

  async getList<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>> {

    if (resource.includes("vault/loanaccount/schedule/")
        // GT-3217: We never want see the schedule list sorted by entry id
        && params.hasOwnProperty("sort")
        && params.sort.hasOwnProperty("field")
        && params.sort.field === "id") {
        const url = window.location.href
        window.location.href=url.replaceAll("sort=id", "sort=paid_at");
    }

    const result = await this.request("GET", resource, params);
    return this.unpackListResult(result);
  }

  async update<RecordType extends RaRecord = RaRecord>(
      resource: string,
      params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> {
    let updatedResource = resource;
    let updatedParams: { [id: string]: any; } = {};
    for (const [key, value] of Object.entries(params)) {
      if (updatedResource.indexOf(`<${key}>`) !== -1) {
        updatedResource = updatedResource.replace(`<${key}>`, value)
      }
      else {
        updatedParams[key] = value;
      }
    }
    const result = await this.request("PUT", updatedResource, updatedParams);
    return { data: result.json };
  }

  async getOne<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetOneParams
  ): Promise<GetOneResult<RecordType>> {

    let getOneResource = resource;
    let getOneParams: { [id: string]: any; } = {};
    for (const [key, value] of Object.entries(params)) {
      if (getOneResource.indexOf(`<${key}>`) !== -1) {
        getOneResource = getOneResource.replace(`<${key}>`, value)
      }
      else {
        getOneParams[key] = value;
      }
    }

    var meta = { ...params.meta };
    if (resource === "vault/loan/loans") {
      meta = { ...meta, lookup_field: "external_id" };
    }
    params.meta = meta;
    const result = await this.request("GET", getOneResource, getOneParams);
    result.json["id"] = result.json["external_id"]
    return { data: result.json };
  }

  async getMany<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> {
    var meta = { ...params.meta };
    if (resource === "vault/loan/loans") {
      meta = { ...meta, lookup_field: "external_id" };
    }
    params.meta = meta;

    if (NATIVE_GET_MANY_RESOURCES.has(resource)) {
      // some of our resources support a query param of `id__in=x,y` which
      // allows us to perform a get-many in a single request...
      const result = await this.request("GET", resource, params);

      // the api doesn't return the records in the order that the IDs appeared
      // in the request - so we must do that ourselves...
      const recordsById = new Map<Identifier, RecordType>(
        result.json.map((v: RecordType) => [v.id, v])
      );

      // pretty sure that items in this array can be null and react-admin's
      // badly thought-through types can fuck right off.
      return { data: params.ids.map((id) => recordsById.get(id)) } as any;
    } else {
      // for resources that don't natively support a get-many style of request,
      // we dispatch multiple requests in an async pool and collect the results.
      const responses: GetOneResult<RecordType>[] = await asyncPool(
        ASYNC_POOL_SIZE,
        params.ids,
        (id: Identifier) => this.getOne(resource, { id })
      );
      return { data: responses.map((result) => result.data) };
    }
  }

  async getManyReference<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> {
    const result = await this.request("GET", resource, params);
    return this.unpackListResult(result);
  }

  async updateMany(
    resource: string,
    params: UpdateManyParams<any>
  ): Promise<UpdateManyResult> {
    await asyncPool(ASYNC_POOL_SIZE, params.ids, (id: Identifier) =>
      this.request("put", resource, { id, data: params.data })
    );
    return {};
  }

  async create<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: CreateParams<any>,
    idempotencyKey: string|null = null,
  ): Promise<CreateResult<RecordType>> {
    const result = await this.request("POST", resource, params, idempotencyKey);
    return { data: result.json };
  }

  async delete<RecordType extends RaRecord = RaRecord>(
    resource: string,
    params: DeleteParams
  ): Promise<DeleteResult<RecordType>> {
    await this.request("DELETE", resource, params);
    // DRF doesn't return the deleted record, so just return the previous data
    // FIXME: this is a terrible hack to get around the type-checker and is technically wrong.
    return { data: params.previousData ?? ({ id: params.id } as any) };
  }

  async deleteMany(
    resource: string,
    params: DeleteManyParams
  ): Promise<DeleteManyResult> {
    // TODO: handle rejections and only return the ids that _were_ deleted...
    await asyncPool(ASYNC_POOL_SIZE, params.ids, (id: Identifier) =>
      this.delete(resource, { id })
    );

    return {};
  }
}
