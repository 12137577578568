import GoogleLogin from "react-google-login";
import * as React from "react";
import { Box, Card, Avatar, makeStyles, Theme } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { useLogin, useNotify, Notification, } from "react-admin";

const useStyles = makeStyles(
  (theme: Theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage:
        "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
    },
    card: {
      minWidth: 300,
      marginTop: "6em",
    },
    avatar: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      backgroundColor: "white",
    },
  }),
  { name: "RaLogin" }
);

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider}>
 *             ...
 *        </Admin>
 *     );
 */
export default function GoogleLoginPage() {
  const classes = useStyles();

  const login = useLogin();
  const notify = useNotify();

// Client ID specific to abacus
// clientId="783927943283-fdr4r4splbumpn3319v30h8ea3bkiued.apps.googleusercontent.com"
// Shared client ID
// clientId="783927943283-vmktpoeqde9q8meiiq2vk442qhd72ak4.apps.googleusercontent.com"


  return (
      <div className={classes.main}>
        <Card className={classes.card}>
          <div className={classes.avatar}>Abacus - Loan Management</div>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          <Box display="flex" alignItems="center" justifyContent="center">
            <GoogleLogin
              clientId="783927943283-vmktpoeqde9q8meiiq2vk442qhd72ak4.apps.googleusercontent.com"
              onSuccess={(s: any) => login(s)}
              isSignedIn={true}
              onFailure={(error: any) =>
                error
                  ? notify(`Google login error: ${error.error ?? error}`, {
                      type: "warning",
                    })
                  : notify("Google login error", { type: "warning" })
              }
              cookiePolicy={"single_host_origin"}
            >
              <div>Sign in with Google</div>
            </GoogleLogin>
          </Box>
        </Card>
        <Notification />
      </div>
  );
}
