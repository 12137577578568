import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {Input} from "@material-ui/core";

export const NumberInput = (props: any) => {
    const { onChange, placeholder, name, error, ...rest } = props;
    return (
        <FormControl variant="standard">
            <Input
                id="standard-adornment-amount"
                margin="dense"
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                error={error}
                type="number"
                {...rest}
            />
        </FormControl>
    );
}