import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {Input, InputAdornment} from "@material-ui/core";

export const PercentInput = (props: any) => {
    const { id, onChange, placeholder, name, error, value, label, ...rest } = props;
    return (
        <FormControl variant="standard">
            <label
                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiFormLabel-filled"
                data-shrink="true">{props.label}</label>
            <Input
                id={props.id}
                startAdornment={<InputAdornment position="start">%</InputAdornment>}
                margin="dense"
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                inputProps={{
                    maxLength: 13,
                    step: "0.5"
                }}
                error={error}
                type="number"
                value={value}
                {...rest}
            />
        </FormControl>
    );
}