import React from 'react';
import 'dayjs/locale/en-gb';
import {BoolDictionary} from "../custom_types/customTypes";
import ConfirmDialog from "./ConfirmDialog";
import {AmountInput} from "../form_inputs/AmountInput";
import {PaidAtInputProps} from "../form_inputs/PaidAtInput";
import {DateInput} from "../form_inputs/DateInput";
import moment from "moment/moment";
import { GetOneResult, useDataProvider} from 'react-admin';

const PlacementValueDialog = (props: {
    id: string; icon: any; button_type: "list" | "show";
    fields: any
}) => {

    const id= props.id
    const button_type = props.button_type

    let data: GetOneResult<any>
    let amount = ""
    let principal = ""
    let interest = ""

    const dataProvider = useDataProvider();

    const changeFigures = async (at_date: any, onChange: any) => {
        const params = { id: id, meta: {date: at_date.format('YYYY-MM-DD')} }
        data = await dataProvider.getOne('vault/collection/placement_value/<id>/preview', params);
        amount = (parseFloat(data.data.principal) + parseFloat(data.data.interest)).toFixed(2)
        principal = data.data.principal
        interest = data.data.interest

        document.querySelectorAll("input").forEach(function (el) {
            if (el.name === "amount") {
                el.value = amount
                onChange("amount")(amount, false)
            }
            if (el.name === "principal") {
                el.value = principal
                onChange("principal")(principal, false)
            }
            if (el.name === "interest") {
                el.value = interest
                onChange("interest")(interest, false)
            }
        });
        onChange("at_date")(at_date, false)
    }

    const handleClickOpen = async (onChange:any) => {
        await changeFigures(moment(), onChange)
    }

    const PlacementValueAtDateInput = (props: PaidAtInputProps) => {
        const  {onChange, error} = props

        const handleChange = async (pickerData: any) => {
            const at_date = moment(pickerData.toDate())
            await changeFigures(at_date, onChange)
        }
        return (
            <DateInput label="Date" name="at_date"
                           onChange={handleChange}
                           error={error}
                           defaultValue={ button_type === "show" ? moment().toDate() : null }
            />
        )
    }

    const getInitValues = () => {
        return {
            "amount": amount,
            "principal": principal,
            "interest": interest,
            "paid_at": moment()
        }
    }

    const DialogField = (props: { fieldKey: string, onChange: any, error: boolean }) => {
        if (props.fieldKey === "amount") {
            return (<AmountInput {...props}
                                 value={amount}
                                 disabled={true}
                                 label="Amount"
            />)
        }
        if (props.fieldKey === "principal") {
            return (<AmountInput {...props}
                                 value={principal}
                                 disabled={true}
                                 id="principal"
                                 name="principal"
                                 placeholder="Principal"
                                 label="Principal"
            />)
        }
        if (props.fieldKey === "interest") {
            return (<AmountInput {...props}
                                 value={interest}
                                 disabled={true}
                                 id="interest"
                                 name="interest"
                                 placeholder="Interest"
                                 label="Interest"
            />)
        }
        return (<PlacementValueAtDateInput {...props} />)
    }

    const getParams = (id: any, values: any) => {
        return {
            data: {
                date: values['at_date'].format('YYYY-MM-DD'),
            }
        }
    }

    let getFormFields = (fields: any, getOnChange: any, errors: BoolDictionary) => {
        return fields.map((fieldKey: string) => (
            <DialogField fieldKey={fieldKey} onChange={getOnChange} error={errors[fieldKey]} key={fieldKey} />
        ));
    }

    let getConfirmDisabled = (values: any) => {
       let msg = ""
       if (values['amount'] === '0.00') {
           msg += "Placement Value can not be £0.00. ";
       }
       if (values["at_date"].format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
            msg += "Placement Date can not be in the future."
        }
       return msg
    }

    return (<ConfirmDialog {...props}
                           getConfirmDisabled={getConfirmDisabled}
                           handleClickOpen={handleClickOpen}
                           getInitValues={getInitValues}
                           getParams={getParams}
                           getFormFields={getFormFields}
                           data_provider_endpoint={"vault/collection/placement_value/" + props.id}
                           data_provider_call_type="create"
                           label="Placement Value"
                           formValidationAlert={"Please enter Placement Value date"}
                           requestErrorMessage="An unexpected error occurred while creating Placement Value. Please report this issue to engineering."
                           redirectTo={'/vault/loan/loans/' + props.id + '/show/transactions/*'}
        />
    )
}

export default PlacementValueDialog