export interface Choice {
  id: string;
  name: string;
}

export const RepaymentMethodChoices: Choice[] = [
  { id: "1", name: "Interest Only" },
  { id: "2", name: "Constant Instalment Repayment" },
  { id: "3", name: "Straight Line Repayment" },
  { id: "4", name: "Mixture of Interest Only and Repayment" },
  { id: "5", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const PaymentMethodChoices: Choice[] = [
  { id: "1", name: "Direct Debit" },
  { id: "2", name: "Cash" },
  { id: "3", name: "Bank Transfer" },
  { id: "4", name: "Cheque" },
  { id: "5", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const paymentFrequencyChoices: Choice[] = [
  { id: "1", name: "Weekly" },
  { id: "2", name: "Fortnightly" },
  { id: "3", name: "Monthly" },
  { id: "4", name: "Quarterly" },
  { id: "5", name: "Semi Annually" },
  { id: "6", name: "Annually" },
  { id: "7", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const purposeChoices: Choice[] = [
  { id: "1", name: "Tuition Fees" },
  { id: "2", name: "Living Expenses" },
  { id: "3", name: "Medical" },
  { id: "4", name: "Home Improvements" },
  { id: "5", name: "Appliance/Furniture" },
  { id: "6", name: "Travel" },
  { id: "7", name: "Debt Consolidation" },
  { id: "8", name: "New Car" },
  { id: "9", name: "Used Car" },
  { id: "10", name: "Other Vehicle" },
  { id: "11", name: "Equipment" },
  { id: "12", name: "Property" },
  { id: "13", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const simpleInterestRateBasisChoices: Choice[] = [
  { id: "1", name: "Fixed" },
  { id: "2", name: "ECB Base rate" },
  { id: "3", name: "1m Euribor" },
  { id: "4", name: "3m Euribor" },
  { id: "5", name: "12m Euribor" },
  { id: "6", name: "UK Base Rate" },
  { id: "7", name: "1m Libor" },
  { id: "8", name: "3m Libor" },
  { id: "9", name: "12m Libor" },
  { id: "10", name: "Lender’s SVR" },
  { id: "11", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const originationChannelChoices: Choice[] = [
  { id: "1", name: "Dealer" },
  { id: "2", name: "Broker" },
  { id: "3", name: "Direct" },
  { id: "4", name: "Indirect" },
  { id: "5", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const interestRateTypeChoices: Choice[] = [
  { id: "1", name: "Fixed Rate Loan" },
  { id: "2", name: "Floating Rate Loan" },
  { id: "3", name: "Other" },
];

export const insuranceTypeChoices: Choice[] = [
  { id: "1", name: "Life, Accident, Sickness & Unemployment Insurance" },
  { id: "2", name: "Life, Accident & Sickness Insurance" },
  { id: "3", name: "Accident, Sickness, & Unemployment" },
  { id: "4", name: "Accident & Sickness Insurance" },
  { id: "5", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const insurancePaymentFrequencyChoices: Choice[] = [
  { id: "1", name: "Weekly" },
  { id: "2", name: "Fortnightly" },
  { id: "3", name: "Monthly" },
  { id: "4", name: "Quarterly" },
  { id: "5", name: "Semi Annually" },
  { id: "6", name: "Annually" },
  { id: "7", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const customerTypeChoices: Choice[] = [
  { id: "New", name: "New" },
  { id: "Existing", name: "Existing" },
];

export const BureauScoreProviderChoices: Choice[] = [
  { id: "1", name: "Callcredit" },
  { id: "2", name: "Experion" },
  { id: "3", name: "Equifax" },
  { id: "4", name: "Schufa" },
  { id: "5", name: "Bureau Krediet Registratie" },
  { id: "6", name: "Internal Score" },
  { id: "7", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const BureauScoreTypeChoices = [
  { id: "1", name: "Generation 8 B F AAM DCM Experian" },
  { id: "2", name: "Generation 8 B F CRS DCM Experian" },
  { id: "3", name: "Generation 7 Mortgage PD Score DCM Experian" },
  { id: "4", name: "FSC109 Risk Navigator Equifax" },
  { id: "5", name: "RNILF02 Risk Navigator Equifax" },
  { id: "6", name: "RNISF02 Risk Navigator Equifax" },
  { id: "7", name: "Internal Scorecard" },
  { id: "8", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const EmploymentStatusChoices = [
  { id: "1", name: "Employed" },
  { id: "2", name: "Full Loan is Guaranteed" },
  { id: "3", name: "Employed with Partial Support" },
  { id: "4", name: "Protected Life-Time Employment" },
  { id: "5", name: "Unemployed" },
  { id: "6", name: "Self-Employed" },
  { id: "7", name: "No Employment - Borrower is Legal Entity" },
  { id: "8", name: "Student" },
  { id: "9", name: "Pensioner" },
  { id: "10", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const ResidentChoices = [
  { id: "1", name: "Resident Less Than 3 Years" },
  { id: "2", name: "Resident 3 Years or More" },
  { id: "3", name: "Not Resident" },
  { id: "ND", name: "No Data" },
];

export const BankruptcyIvaChoices = [
  { id: "Y", name: "Yes" },
  { id: "N", name: "No" },
  { id: "ND", name: "No Data" },
];

export const PrimaryIncomeVerificationChoices = [
  { id: "1", name: "Self Certified with Affordability Confirmation" },
  { id: "2", name: "Verified" },
  { id: "3", name: "Partially Verified" },
  { id: "4", name: "Non-Verified Income Fast Track" },
  { id: "5", name: "Other" },
  { id: "ND", name: "No Data" },
];

export const GeographicRegionTypeChoices: Choice[] = [
  { id: "ukc", name: "North East (England)" },
  { id: "ukd", name: "North West (England)" },
  { id: "uke", name: "Yorkshire and the Humber" },
  { id: "ukf", name: "East Midlands (England)" },
  { id: "ukg", name: "West Midlands (England)" },
  { id: "ukh", name: "East of England" },
  { id: "uki", name: "London" },
  { id: "ukj", name: "South East (England)" },
  { id: "ukk", name: "South West (England)" },
  { id: "ukl", name: "Wales" },
  { id: "ukm", name: "Scotland" },
  { id: "ukn", name: "Northern Ireland" },
  { id: "ukz", name: "Extra-Regio NUTS 1" },
];

export const PoolIdentifierTypeChoices = [
  { id: "vaultfinance", name: "Vault Finance" },
  { id: "fairscore", name: "Fair Score" },
  { id: "jefferies", name: "Jefferies" },
  { id: "ND", name: "No Data" },
];
