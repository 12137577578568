import React from "react";
import { useRecordContext } from "ra-core";
import get from "lodash/get";
import { SelectField } from "react-admin";

const EMPTY_TEXT = "-";

const CustomSelectField = ({ choices, ...props }: any) => {
  const record = useRecordContext(props);
  const { _, source } = props;

  const value = get(record, source);

  const choiceExists = choices.some((choice: any) => choice.id === value);

  if (!choiceExists && value !== null && value !== undefined) {
    return <span>{value}</span>;
  }

  return <SelectField {...props} choices={choices} emptyText={EMPTY_TEXT} />;
};

export default CustomSelectField;
