import React from "react";
import {MoneyInput} from "./MoneyInput";

interface AmountInputProps {
    id: string,
    name: string,
    placeholder: string,
    onChange: any,
    error: boolean
    value?: any
    disabled?: boolean
    label?: string
}

export const AmountInput = (props: AmountInputProps) => {
    const  {onChange, error} = props
    const handleChange = (event: any) => {
        const amount = event.target.value
        if (amount.length === 0) {
            onChange(null, true)
        } else {
            onChange(amount, false)
        }
    }
    if (props.disabled) {
        return (
            <MoneyInput placeholder={props.placeholder} name={props.name}
                        onChange={handleChange}
                        error={error}
                        value={props.value}
                        disabled={true}
                        label={props.label}
            />
        )
    }
    return (
        <MoneyInput placeholder="Amount" name="amount"
                    onChange={handleChange}
                    error={error}
                    value={props.value}
        />
    )
}

AmountInput.defaultProps = {
    id: "standard-adornment-amount",
    placeholder: "Amount",
    name: "amount",
    disabled: false,
}