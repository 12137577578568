import {
    Show,
    TabbedShowLayout,
    Tab,
    Resource,
    useGetRecordId,
    TopToolbar,
    ShowProps, TabbedShowLayoutTabs,
} from "react-admin";
import Schedule from "../loanaccount/Schedule";
import Mutations from "../loanaccount/Mutations";
import PlacementValues from "../loanaccount/PlacementValues";
import Transactions from "../loanaccount/Transactions";
import Documents from "../loanaccount/Documents";
import Arrears from "../loanaccount/Arrears";
import DailyBoeReport from "../loanaccount/DailyBoeReport";
import PostTransactionDialog from "../loanaccount/PostTransaction";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddDDChange from "../loanaccount/AddDDChange";
import ChangeCircleSharpIcon from "@mui/icons-material/ChangeCircleSharp";
import AddPaymentHoliday from "../loanaccount/AddPaymentHoliday";
import AddReducedPaymentOrDMP from "../loanaccount/AddReducedPaymentOrDMPDialog";
import AdjustSharpIcon from "@mui/icons-material/AdjustSharp";
import LoanDetail from "./LoanDetail";
import RepayLoanDialog from "../loanaccount/PrepayLoanDialog";
import PlacementValueDialog from "../loanaccount/PlacementValueDialog";
import {useRecordContext} from "ra-core";

const LoanActions = (props: any) => {
    const record = useRecordContext(props);
    const loan_closed = record && record.hasOwnProperty('abacus_collections_status') && record.abacus_collections_status === "CLOSED"
//     if (loan_closed) {
//         return null
//     }
    return (
      <TopToolbar>
        <AddDDChange id={props.id} icon={<ChangeCircleSharpIcon />} button_type="show" fields={["regular_payment_day", "effective_date", "contracted"]}/>
        <AddPaymentHoliday id={props.id} icon={<ChangeCircleSharpIcon />} button_type="show" fields={["next_payment_on_or_after", "effective_date", "contracted"]} />
        <AddReducedPaymentOrDMP
            id={props.id}
            icon={<AdjustSharpIcon />}
            button_type="show"
            fields={["amount", "interest", "effective_date"]}
            purpose="REDUCED_PAYMENT"
            label="Reduced Payment"
        />
        <AddReducedPaymentOrDMP
            id={props.id}
            icon={<AdjustSharpIcon />}
            button_type="show"
            fields={["amount", "interest", "effective_date"]}
            purpose="DMP"
            label="DMP"
        />
        <PostTransactionDialog id={props.id} type="REPAYMENT" icon={<AddCircleIcon />} button_type="show" fields={["amount", "paid_at"]} />
        <PostTransactionDialog id={props.id} type="INTEREST_WAIVER" icon={<RemoveCircleIcon />} button_type="show" fields={["amount", "paid_at"]} />
        <PostTransactionDialog id={props.id} type="REFUND" icon={<RemoveCircleIcon />} button_type="show" fields={["amount", "paid_at"]} />
        <PostTransactionDialog id={props.id} type="PRINCIPAL_WRITEOFF" icon={<RemoveCircleIcon />} button_type="show" fields={["amount", "paid_at"]} />
        <PostTransactionDialog id={props.id} type="DISBURSMENT" icon={<RemoveCircleIcon />} button_type="show" fields={["amount", "paid_at"]} />
        <RepayLoanDialog id={props.id} icon={<RemoveCircleIcon />} button_type="show" fields={["amount", "principal", "interest", "paid_at"]} />
        <PlacementValueDialog id={props.id} icon={<RemoveCircleIcon />} button_type="show" fields={["amount", "principal", "interest", "paid_at"]} />
      </TopToolbar>
    );
};

const LoanShow = (props: ShowProps) => {
  const recordId = useGetRecordId();
  return (
      <Show actions={<LoanActions id={recordId}/>} {...props}>
          <TabbedShowLayout  tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}>
              <Tab label="Loan Details">
                <LoanDetail />
              </Tab>
              <Tab label="Documents" path="documents">
                  <Resource name={"vault/loan/loans/" + recordId+"/documents"}
                            options={{label: "Documents"}} {...Documents} />
              </Tab>
              <Tab label="Actual Schedule" path="actual_schedule">
                  <Resource name={"vault/loanaccount/schedule/" + recordId + "/actual"}
                            options={{label: "Actual Schedule"}} {...Schedule} />
              </Tab>
              <Tab label="Expected Schedule" path="expected_schedule">
                  <Resource name={"vault/loanaccount/schedule/" + recordId + "/expected"}
                            options={{label: "Expected Schedule"}} {...Schedule} />
              </Tab>
              <Tab label="Contractual Schedule" path="contractual_schedule">
                  <Resource name={"vault/loanaccount/schedule/" + recordId + "/contractual"}
                            options={{label: "Contractual Schedule"}} {...Schedule} />
              </Tab>
              <Tab label="Original Schedule" path="original_schedule">
                  <Resource name={"vault/loanaccount/schedule/" + recordId + "/original"}
                            options={{label: "Original Schedule"}} {...Schedule} />
              </Tab>
              <Tab label="Accepted Schedule" path="accepted_schedule">
                  <Resource name={"vault/loanaccount/schedule/" + recordId + "/accepted"}
                            options={{label: "Accepted Schedule"}} {...Schedule} />
              </Tab>
              <Tab label="Mutations" path="mutations">
                  <Resource name={"vault/loan/mutation/?loan__external_id=" + recordId}
                            options={{label: "Mutations"}} {...Mutations} />
              </Tab>
              <Tab label="Placement Values" path="placement_value">
                  <Resource name={"vault/collection/placement_value/" + recordId}
                            options={{label: "Placement Values"}} {...PlacementValues} />
              </Tab>
              <Tab label="Transactions" path="transactions">
                  <Resource name={"vault/loanaccount/transactions/" + recordId}
                            options={{label: "Transactions"}} {...Transactions} />
              </Tab>
              <Tab label="Arrears" path="arrears">
                  <Resource name={"vault/loanaccount/schedule/" + recordId + "/arrears"}
                            options={{label: "Arrears"}} {...Arrears} />
              </Tab>
              <Tab label="Daily BoE Report" path="daily_boe_report/*">
                    <DailyBoeReport id={recordId} />
              </Tab>
          </TabbedShowLayout>
      </Show>
  );
};

export default LoanShow;
