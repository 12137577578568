import {
    Datagrid,
    DateField,
    List,
    SelectField,
} from "react-admin";
import {MoneyField} from "../admin/MoneyField";
const ScheduleEntries = () => {
    return (
        <List>
            <Datagrid bulkActionButtons={false}>
                <SelectField label="Entry type" source="schedule_entity.entry_type" choices={[
                    { id: "DISBURSMENT", name: "Disbursment" },
                    { id: "PLANNED_PAYMENT", name: "Planned payment" },
                    { id: "REPAYMENT", name: "Repayment" },
                    { id: "INTEREST_WAIVER", name: "Interest waiver" },
                    { id: "PRINCIPAL_WRITEOFF", name: "Principal write off" },
                    { id: "REFUND", name: "Refund" },
                ]} sortable={false} />
                <DateField label="Due date" source="schedule_entity.due_date" locales="en-UK" sortable={false} />
                <MoneyField label="Opening bal" source="schedule_entity.opening_balance" sortable={false} />
                <MoneyField label="Closing bal" source="schedule_entity.closing_balance" sortable={false} />
                <MoneyField label="Amount" source="schedule_entity.amount" sortable={false} />
                <MoneyField label="Interest" source="schedule_entity.interest" sortable={false} />
                <MoneyField label="Interest waived" source="schedule_entity.interest_waived" sortable={false} />
            </Datagrid>
        </List>
    );
};

const Schedule = { list: ScheduleEntries };

export default Schedule;