import moment from "moment/moment";
import React from "react";
import {DateInput} from "./DateInput";

export interface PaidAtInputProps {
    onChange: any,
    error: boolean
    value?: any
}

export const PaidAtInput = (props: PaidAtInputProps) => {
    const  {onChange, error} = props
    const handleChange = (pickerData: any) => {
        if (Object.keys(pickerData).length === 0) {
            onChange(null, true)
        } else {
            onChange(moment(pickerData.toDate()).format('YYYY-MM-DD HH:mm:ss'), false)
        }
    }
    return (
        <DateInput label="Paid At" name="paid_at"
                       onChange={handleChange}
                       error={error}
                       maxDate={new Date()}
                       defaultValue={null}
        />
    )
}
